import { invokeApi } from "../../bl_libs/invokeApi";

export const documentListApiSlug = async (id) => {
  const requestObj = {
    path: `/api/lesson_document/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const documentListApi = async (data) => {
  const requestObj = {
    path: `/api/lesson_document/`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const AddLessonDocumentApi = async (data) => {
  const requestObj = {
    path: `/api/lesson_document/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const EditLessonDocumentApi = async (data, slug) => {
  console.log(slug, "slug");
  const requestObj = {
    path: `/api/lesson_document/${slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const deleteLessonDocumentApi = async (slug) => {
  const requestObj = {
    path: `/api/lesson_document/${slug}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const deleteLessonApi = async (slug) => {
  const requestObj = {
    path: `/api/lesson/${slug}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
